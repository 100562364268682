import { DayAndTime } from 'interfaces/dayAndTime';
import { Moment } from 'moment';
import { SMConfig } from 'redux/definitions/smConfig';
import { toTime } from 'utils/timeUtils';

export interface SMBaseConfiguration {
  readonly enabled: boolean;
  readonly connectionType: 'acceptor' | 'initiator';
  readonly senderComp: string;
  readonly targetComp: string;
  readonly start: DayAndTime;
  readonly end: DayAndTime;
  readonly frequency: 'D' | 'W';
  readonly checkVacation: boolean;
}

export interface SMNetworkAddress {
  readonly ip: string;
  readonly port: number | null;
}

export interface VPNForm {
  readonly party: any;
}

export interface XCONNForm {
  readonly physicalDetails: any;
}

export interface SMNetworkDetails {
  readonly ip1: SMNetworkAddress;
  readonly ip2?: SMNetworkAddress;
  readonly ip3?: SMNetworkAddress;
  readonly networkVendor: string;
  readonly omsVendor: string;
  // FIXME: use the actual type
  readonly vpnForm?: VPNForm;
  readonly xconnForm?: XCONNForm;
}

export interface SMSolaceDetails {
  readonly routerName: string;
  readonly solaceClientId: string;
  readonly listenTopic: string;
  readonly adminTopic: string;
  readonly heartbeatTopic: string;
}

export interface SMLogControl {
  readonly fileLogPath: string;
  readonly fileName: string;
  readonly logRotateEnabled: boolean;
  readonly sizeLimitForRotate: number;
  readonly dayTimeToRotate: DayAndTime;
  readonly multiDayStore: boolean;

  readonly storeKeyTags?: string;
  readonly storeValueTags?: string;
}

export interface SMDefaultEntry {
  readonly attributename: string;
  readonly attributedefault: string;
  readonly applevel: string;
  // This needs to be interpreted in a specific way
  readonly allowedvalues: string;
}

export type SMDefaultConfiguration = readonly SMDefaultEntry[];

export const findDefaultSMValueByName = (
  defaultConfiguration: Record<string, string>,
  name: string
): string | undefined => {
  return defaultConfiguration[name];
};

export interface SMDatabaseDetails {
  readonly dbServerName: string;
}

export interface SMFIXSessionDetails {
  readonly fixPassword: string;
  readonly direction: string;
  readonly fixVersion: string;
  readonly heartbeatInt: number;
  readonly socketReuseAddress: boolean;
  readonly socketNoDelay: boolean;
  readonly validateFieldsOutOfOrder: boolean;
  readonly validateFieldsHaveValues: boolean;
  readonly validateUserDefinedFields: boolean;
  readonly refreshOnLogin: boolean;
  readonly useDataDictionary: boolean;
  readonly dataDictionary: string;
  readonly logonTimeout: number;
  readonly logoutTimeout: number;
  readonly logonTags: string;
  readonly rulesInModule: string;
  readonly rulesOutModule: string;
  readonly rulesPath: string;
  readonly fileStorePath: string;
  readonly persistPath: string;
}

export enum SMRefDataConfigFileUpdateMode {
  invalid = -1,
  periodic = 1,
  daily = 2,
  weekly = 3,
  monthly = 4,
}

interface SMRefDataConfigFileBase {
  readonly id: string;
  readonly file: string;
  readonly keys: string;
  readonly updateMode?: number;
}

export interface SMRefDataConfigFilePeriodicUpdate extends SMRefDataConfigFileBase {
  readonly updateMode: SMRefDataConfigFileUpdateMode.periodic;
  readonly interval: number;
}

export interface SMRefDataConfigFileDailyUpdate extends SMRefDataConfigFileBase {
  readonly updateMode: SMRefDataConfigFileUpdateMode.daily;
  readonly when: Moment;
}

export interface SMRefDataConfigFileWeeklyUpdate extends SMRefDataConfigFileBase {
  readonly updateMode: SMRefDataConfigFileUpdateMode.weekly;
  readonly dayOfWeek: number;
  readonly when: Moment;
}

export interface SMRefDataConfigFileMonthlyUpdate extends SMRefDataConfigFileBase {
  readonly updateMode: SMRefDataConfigFileUpdateMode.monthly;
  readonly day: '30';
  readonly when: Moment;
}

export interface SMRefDataConfigFileInvalid extends SMRefDataConfigFileBase {
  readonly updateMode: SMRefDataConfigFileUpdateMode.invalid;
}

export type SMRefDataConfigFile =
  | SMRefDataConfigFilePeriodicUpdate
  | SMRefDataConfigFileDailyUpdate
  | SMRefDataConfigFileWeeklyUpdate
  | SMRefDataConfigFileMonthlyUpdate
  | SMRefDataConfigFileInvalid;

export interface SMFeatures {
  readonly processMultiLegMode: string;
  readonly marketDataMode: boolean;
  readonly dynamicDelimited: number;
  readonly refConfigFiles: readonly SMRefDataConfigFile[];
  readonly refConfigFilePath: string;
}

// // We have to use the following instead
export interface BackendSMDefaultBase {
  // Seems to be an enum
  readonly ConnectionType: 'initiator' | 'acceptor';
  readonly EndTime: string;
  readonly FileLogPath: string;
  readonly FileName: string;
  readonly HeartBtInt: string;
  readonly RefreshOnLogon: string;
  readonly RootDirectory: string;

  readonly SocketNodelay: string;
  readonly StartTime: string;
  readonly UseDataDictionary: string;
  readonly UseLocalTime: string;
  readonly ValidateFieldsHaveValues: string;
  readonly ValidateFieldsOutOfOrder: string;
  readonly ValidateUserDefinedFields: string;

  // FIXME: seems to be deleted
  readonly SocketReuseAddress: string;

  readonly DBServerName: string;
}

export interface BackendSMDefaultAcceptor extends BackendSMDefaultBase {
  readonly ConnectionType: 'acceptor';
}

export interface BackendSMDefaultInitiator extends BackendSMDefaultBase {
  readonly ConnectionType: 'initiator';
  readonly SocketConnectHost: string;
  readonly SocketConnectHost1: string;
  readonly SocketConnectHost2: string;
  readonly SocketConnectPort: string;
  readonly SocketConnectPort1: string;
  readonly SocketConnectPort2: string;
}

export type BackendSMDefault = BackendSMDefaultInitiator | BackendSMDefaultAcceptor;

export interface BackendSMSession {
  readonly Enable: string;
  readonly BeginString: string;
  readonly Direction: string;
  readonly SenderCompID: string;
  readonly TargetCompID: string;
  readonly HeartBtInt: string;
  readonly FileStorePath: string;
  readonly PersistPath: string;
  readonly AdminTopic: string;
  readonly DataDictionary: string;
  readonly LogonTimeout: string;
  readonly LogoutTimeout: string;
  readonly ListenTopic: string;
  readonly RulesPath: string;
  readonly RulesInModules: string;
  readonly RulesOutModules: string;
  readonly UseDataDictionary: string;
  readonly PersistMessages: string;
  readonly RefreshOnLogon: string;
  readonly StartTime: string;
  readonly EndTime: string;
  readonly UseLocalTime: string;
  readonly SendRedundantResendRequests: string;
  readonly ValidateFieldsOutOfOrder: string;
  readonly ValidateFieldsHaveValues: string;
  readonly ValidateUserDefinedFields: string;
  readonly SolaceRouterName: string;
  readonly SolaceClientID: string;
  readonly HeartBeatTopic: string;
  readonly InstanceID: string;
  readonly DynamicDelimited: string;
  readonly LOG_ROTATE_ENABLE: string;
  readonly SIZE_LIMIT_FOR_ROTATE: string;

  readonly MULTI_DAY_STORE: string;
  readonly STORE_KEY_TAGS?: string;
  readonly STORE_VALUE_TAGS?: string;
  readonly ProcessMultiLegMode: string;
  readonly MarketDataMode: boolean;
  readonly DyListenTopicPrefix?: string;
  readonly RefDataConfFile: string;
  readonly LogonTags: string;
}

export interface SMConfigContent {
  readonly DEFAULT: BackendSMDefault;
  readonly SESSION: BackendSMSession;
}

export interface BackendRefDataConfigFile {
  readonly File: string;
  readonly Keys: string;
  readonly UpdateMode: number;
  readonly Interval?: number;
  readonly Day?: number;
  readonly When?: string;
}

export interface BackendSMConfig {
  readonly sessionid: string;
  readonly beginstring: string;
  readonly sendercompid: string;
  readonly targetcompid: string;
  readonly clientid: string;
  readonly starttime: string;
  readonly endtime: string;
  readonly startday: number;
  readonly endday: number;
  readonly frequency: 'D' | 'W';
  readonly check_vacation: boolean;
  readonly topics: string;
  readonly environment: string;
  readonly active: any;
  readonly admintopic: string;
  readonly cfgcontent: SMConfigContent | null;
  readonly cfgver: number;
  readonly isapproved: boolean;
  readonly sessiontype: string | null;
  readonly refcfgcontent: Record<string, BackendRefDataConfigFile>;
}

export class BackendRefDataConfigFile {
  public static fromSMRefDataConfigFile(value: SMRefDataConfigFile): BackendRefDataConfigFile {
    switch (value.updateMode) {
      case SMRefDataConfigFileUpdateMode.periodic:
        return {
          File: value.file,
          Interval: value.interval,
          Keys: value.keys,
          UpdateMode: value.updateMode,
        };
      case SMRefDataConfigFileUpdateMode.daily:
        return {
          File: value.file,
          Keys: value.keys,
          UpdateMode: value.updateMode,
          When: value.when.format('HH:mm:ss'),
        };
      case SMRefDataConfigFileUpdateMode.weekly:
        return {
          File: value.file,
          Keys: value.keys,
          UpdateMode: value.updateMode,
          When: value.when.format('HH:mm:ss'),
          Day: value.dayOfWeek,
        };
      case SMRefDataConfigFileUpdateMode.monthly:
        return {
          File: value.file,
          Keys: value.keys,
          UpdateMode: value.updateMode,
          When: value.when.format('HH:mm:ss'),
          Day: 30,
        };
      case undefined:
        throw new Error('cannot save an invalid value');
    }
  }
}

const toString = (value: any): string | undefined | null => {
  if (value === null) {
    return null;
  } else if (value === undefined) {
    return undefined;
  }

  return String(value);
};

export class BackendSMConfig {
  public static empty(): BackendSMConfig {
    return {
      active: false,
      admintopic: '',
      beginstring: '',
      cfgcontent: {
        DEFAULT: {} as BackendSMDefault,
        SESSION: {} as BackendSMSession,
      },
      clientid: '',
      endday: 0,
      endtime: '',
      environment: '',
      frequency: 'D',
      check_vacation: true,
      sendercompid: '',
      sessionid: '',
      startday: 0,
      starttime: '',
      targetcompid: '',
      topics: '',
      refcfgcontent: {},
      sessiontype: null,
      cfgver: 0,
      isapproved: false,
    };
  }

  public static fromSMConfig(smConfig: SMConfig): BackendSMConfig {
    const {
      base,
      solace,
      network,
      fixSessionDetails,
      logControl,
      features,
      originalConfig,
      databaseDetails,
    } = smConfig;

    const { DEFAULT, SESSION } = originalConfig?.cfgcontent ?? {
      DEFAULT: {} as BackendSMDefault,
      SESSION: {} as BackendSMSession,
    };

    const { refConfigFiles } = features;

    return {
      active: base.enabled,
      admintopic: solace.adminTopic,
      beginstring: fixSessionDetails.fixVersion,
      environment: originalConfig?.environment,
      // FIXME: this needs to be correctly considered
      frequency: base.frequency,
      sendercompid: base.senderComp,
      sessionid: originalConfig?.sessionid,
      startday: base.start.dayOfWeek,
      starttime: toTime(base.start.time),
      endday: base.end.dayOfWeek,
      check_vacation: base.checkVacation,
      endtime: toTime(base.end.time),
      targetcompid: base.targetComp,
      clientid: solace.solaceClientId,
      // FIXME: not sure what this is
      topics: '',
      cfgcontent: {
        DEFAULT: {
          ConnectionType: base.connectionType,
          SocketReuseAddress: toString(fixSessionDetails.socketReuseAddress),
          SocketNodelay: toString(fixSessionDetails.socketNoDelay),
          SocketConnectHost: network.ip1.ip,
          SocketConnectPort: toString(network.ip1.port),
          SocketConnectHost1: network.ip1.ip,
          SocketConnectPort1: toString(network.ip1.port),
          SocketConnectHost2: network.ip1.ip,
          SocketConnectPort2: toString(network.ip1.port),
          EndTime: DEFAULT.EndTime,
          HeartBtInt: toString(fixSessionDetails.heartbeatInt),
          FileLogPath: logControl.fileLogPath,
          FileName: logControl.fileName,
          RefreshOnLogon: toString(fixSessionDetails.refreshOnLogin),
          StartTime: DEFAULT.StartTime,
          RootDirectory: DEFAULT.RootDirectory,
          UseDataDictionary: toString(fixSessionDetails.useDataDictionary),
          UseLocalTime: DEFAULT.UseLocalTime,
          ValidateFieldsHaveValues: toString(fixSessionDetails.validateFieldsHaveValues),
          ValidateFieldsOutOfOrder: toString(fixSessionDetails.validateFieldsOutOfOrder),
          ValidateUserDefinedFields: toString(fixSessionDetails.validateUserDefinedFields),
          DBServerName: toString(databaseDetails.dbServerName),
        },
        SESSION: {
          Enable: toString(base.enabled),
          AdminTopic: solace.adminTopic,
          HeartBeatTopic: solace.heartbeatTopic,
          ListenTopic: solace.listenTopic,
          BeginString: fixSessionDetails.fixVersion,
          HeartBtInt: toString(fixSessionDetails.heartbeatInt),
          FileStorePath: fixSessionDetails.fileStorePath,
          Direction: fixSessionDetails.direction,
          DataDictionary: fixSessionDetails.dataDictionary,
          LogonTimeout: toString(fixSessionDetails.logonTimeout),
          LogoutTimeout: toString(fixSessionDetails.logoutTimeout),
          PersistPath: fixSessionDetails.persistPath,
          RefreshOnLogon: toString(fixSessionDetails.refreshOnLogin),
          RulesInModules: fixSessionDetails.rulesInModule,
          RulesOutModules: fixSessionDetails.rulesOutModule,
          RulesPath: fixSessionDetails.rulesPath,
          SenderCompID: base.senderComp,
          TargetCompID: base.targetComp,
          UseDataDictionary: toString(fixSessionDetails.useDataDictionary),
          ValidateFieldsHaveValues: toString(fixSessionDetails.validateFieldsHaveValues),
          ValidateFieldsOutOfOrder: toString(fixSessionDetails.validateFieldsOutOfOrder),
          ValidateUserDefinedFields: toString(fixSessionDetails.validateUserDefinedFields),
          PersistMessages: SESSION.PersistMessages,
          StartTime: toTime(base.start.time),
          EndTime: toTime(base.end.time),
          DynamicDelimited: toString(features.dynamicDelimited),
          InstanceID: SESSION.InstanceID,
          LOG_ROTATE_ENABLE: toString(logControl.logRotateEnabled),
          MULTI_DAY_STORE: toString(logControl.multiDayStore),
          STORE_KEY_TAGS: toString(logControl.storeKeyTags),
          STORE_VALUE_TAGS: toString(logControl.storeValueTags),
          SendRedundantResendRequests: SESSION.SendRedundantResendRequests,
          SIZE_LIMIT_FOR_ROTATE: toString(logControl.sizeLimitForRotate),
          SolaceClientID: solace.solaceClientId,
          SolaceRouterName: solace.routerName,
          UseLocalTime: toString(SESSION.UseLocalTime),
          ProcessMultiLegMode: features.processMultiLegMode,
          MarketDataMode: SESSION.MarketDataMode,
          RefDataConfFile: features.refConfigFilePath,
          LogonTags: fixSessionDetails.logonTags,
        },
      },
      isapproved: true,
      cfgver: 8,
      sessiontype: null,
      // FIXME: here we should use the values captured in the UI
      refcfgcontent: refConfigFiles?.reduce(
        (
          data: Record<string, BackendRefDataConfigFile>,
          item: SMRefDataConfigFile
        ): Record<string, BackendRefDataConfigFile> => {
          const { id } = item;

          return {
            ...data,
            [id]: BackendRefDataConfigFile.fromSMRefDataConfigFile(item),
          };
        },
        {}
      ),
    };
  }
}

export const isInitiator = (data: unknown): data is BackendSMDefaultInitiator => {
  if (data === null || data === undefined || typeof data !== 'object') {
    return false;
  }

  return 'ConnectionType' in data && (data as any).ConnectionType === 'initiator';
};

import { Brokerage, brokerageFloor, brokerageTop } from 'types/brokerage';
import { DropdownOption } from 'types/dropdownOption';
import { Identifiable } from 'types/identifiable';
import { WhitelistItem } from 'types/whitelistItem';
import { randomString } from 'utils/randomString';

export enum FirmType {
  Bank = 'BANK',
  Broker = 'BROKER',
  STP = 'STP',
  SEF = 'SEF',
  Connectivity = 'CONN',
}

export enum STPMsgType {
  FPML = 'FPML',
  AE = 'AE',
  EIGHT = '8',
  CSV = 'CSV',
}

export enum STPRoute {
  NONE = 'NONE',
  DIRECT = 'DIRECT',
  MRKT = 'MRKT',
  TRTN = 'TRTN',
  TRTNAFF = 'TRTNAFF',
}

export interface BrokerageLimit {
  readonly latam?: number;
  readonly g10?: number;
  readonly asia?: number;
  readonly ceemea?: number;
  readonly pm?: number;
}

export interface Entity {
  readonly entityID: number;
  readonly legalName: string;
  readonly orgCode: string;
  readonly tradingEntityCode: string;
  readonly legalEntityID: string;
  readonly default: number;
  readonly MSEntityID: string;
  readonly MSBranchID: string;
  readonly TRTNEntityID: string;
  readonly UBSEntityID: string;
  readonly MSCOEntityID: string;
  readonly SANTEntityID: string;
  readonly brokerOfCredit: string;
  readonly businessAddress: string;
  readonly workPhone: string;
  readonly isNDF: boolean;
  readonly isFXO: boolean;
}

export class Entity {
  public static empty(): Entity {
    return {
      entityID: -1,
      legalName: '',
      orgCode: '',
      tradingEntityCode: '',
      legalEntityID: '',
      default: 0,
      MSEntityID: '',
      MSBranchID: '',
      TRTNEntityID: '',
      UBSEntityID: '',
      MSCOEntityID: '',
      SANTEntityID: '',
      brokerOfCredit: '',
      businessAddress: '',
      workPhone: '',
      isNDF: false,
      isFXO: true,
    };
  }
}

export interface EntityPayload {
  readonly EntityID: number;
  readonly LegalName: string;
  readonly OrgCode: string;
  readonly TradingEntityCode: string;
  readonly legalEntityID: string;
  readonly Default: number;
  readonly MSEntityID: string;
  readonly MSBranchID: string;
  readonly TRTNEntityID: string;
  readonly UBSEntityID: string;
  readonly MSCOEntityID: string;
  readonly SANTEntityID: string;
  readonly BrokerOfCredit: string;
  readonly business_address: string;
  readonly work_phone: string;
  readonly isNDF: boolean;
  readonly isFXO: boolean;
}

export class EntityPayload {
  public static fromEntity(entity: Entity): EntityPayload {
    return {
      EntityID: entity.entityID,
      LegalName: entity.legalName,
      OrgCode: entity.orgCode,
      TradingEntityCode: entity.tradingEntityCode,
      legalEntityID: entity.legalEntityID,
      Default: entity.default,
      MSEntityID: entity.MSEntityID,
      MSBranchID: entity.MSBranchID,
      TRTNEntityID: entity.TRTNEntityID,
      UBSEntityID: entity.UBSEntityID,
      MSCOEntityID: entity.MSCOEntityID,
      SANTEntityID: entity.SANTEntityID,
      BrokerOfCredit: entity.brokerOfCredit,
      business_address: entity.businessAddress,
      work_phone: entity.workPhone,
      isFXO: entity.isFXO,
      isNDF: entity.isNDF,
    };
  }
}

export interface MPID {
  readonly mpid: string;
  readonly appid: string;
}

export interface Firm {
  readonly id: number;

  readonly firmID: number;
  readonly firmName: string;
  readonly MPIDS: readonly MPID[];
  readonly firmLabel: string;
  readonly firmType: FirmType;
  readonly STPMsgType?: STPMsgType;
  readonly STPRoute?: STPRoute;
  readonly STPDestination?: string;
  readonly brokerage: Brokerage;
  readonly entities?: readonly Entity[];
  readonly whitelist: readonly WhitelistItem[];
  readonly firmCategory: DropdownOption[];
  readonly businessAddress: string;
  readonly workPhone: string;
  readonly cellPhone: string;

  readonly sessionIds: readonly DropdownOption[];
}

export class Firm implements Identifiable {
  public static fromPayload(firm: FirmPayload, id?: number): Firm {
    const { MPIDS } = firm;

    return {
      id: id,
      firmID: id ?? firm.FirmID,
      firmLabel: firm.FirmLabel,
      firmName: toFirmName(MPIDS, firm.FirmName),
      MPIDS: MPIDS,
      firmType: firm.FirmType,
      firmCategory: [
        ...(firm.isNDF
          ? [
              {
                id: 'ndf',
                name: 'NDF',
              },
            ]
          : []),
        ...(firm.isFXO
          ? [
              {
                id: 'fxo',
                name: 'FXO',
              },
            ]
          : []),
        ...(firm.isRoute
          ? [
              {
                id: 'route',
                name: 'ROUTE',
              },
            ]
          : []),
      ],
      sessionIds:
        firm.SESSIONIDS?.map((id: string): DropdownOption => ({ id: id, name: id })) ?? [],
      entities: firm.Entities.map((entity: EntityPayload): Entity => {
        return {
          entityID: entity.EntityID,
          legalName: entity.LegalName,
          orgCode: entity.OrgCode,
          tradingEntityCode: entity.TradingEntityCode,
          legalEntityID: entity.legalEntityID,
          default: entity.Default,
          MSEntityID: entity.MSEntityID,
          MSBranchID: entity.MSBranchID,
          TRTNEntityID: entity.TRTNEntityID,
          UBSEntityID: entity.UBSEntityID,
          MSCOEntityID: entity.MSCOEntityID,
          SANTEntityID: entity.SANTEntityID,
          brokerOfCredit: entity.BrokerOfCredit,
          businessAddress: entity.business_address,
          workPhone: entity.work_phone,
          isFXO: entity.isFXO,
          isNDF: entity.isNDF,
        };
      }),
      whitelist: firm.IPAddresses?.map((ip: string): WhitelistItem => ({ ipAddress: ip })) ?? [],
      brokerage: Brokerage.fromPayload(firm),
      STPRoute: firm.STPRoute,
      STPMsgType: firm.STPMsgType,
      businessAddress: firm.business_address,
      workPhone: firm.work_phone,
      cellPhone: firm.cell,
    };
  }

  public static fromBackendFirm(firm: BackendFirm): Firm {
    const { MPIDS } = firm;

    return {
      id: firm.FirmID,
      firmID: firm.FirmID,
      firmLabel: firm.FirmLabel,
      firmName: toFirmName(MPIDS, firm.FirmName),
      MPIDS: MPIDS,
      firmType: firm.FirmType,
      entities: firm.Entities,
      whitelist: firm.IPAddresses?.map((ip: string): WhitelistItem => ({ ipAddress: ip })) ?? [],
      brokerage: Brokerage.fromFirm(firm),
      STPRoute: firm.STPRoute,
      STPMsgType: firm.STPMsgType,
      firmCategory: [
        ...(firm.isNDF
          ? [
              {
                id: 'ndf',
                name: 'NDF',
              },
            ]
          : []),
        ...(firm.isFXO
          ? [
              {
                id: 'fxo',
                name: 'FXO',
              },
            ]
          : []),
        ...(firm.isRoute
          ? [
              {
                id: 'route',
                name: 'ROUTE',
              },
            ]
          : []),
      ],
      businessAddress: firm.business_address,
      workPhone: firm.work_phone,
      cellPhone: firm.cell,
      sessionIds:
        firm.SESSIONIDS?.map((id: string): DropdownOption => ({ id: id, name: id })) ?? [],
    };
  }

  public static empty(): Firm {
    return emptyFirm;
  }
}

export interface FirmPayload {
  readonly FirmID?: number;
  readonly FirmName: string;
  readonly FirmLabel: string;
  readonly FirmType: FirmType;
  readonly STPMsgType?: STPMsgType;
  readonly STPRoute?: STPRoute;
  readonly STPDestination?: string;
  readonly BrokerageTop?: BrokerageLimit;
  readonly BrokerageFloor?: BrokerageLimit;
  readonly Entities?: readonly EntityPayload[];
  readonly IPAddresses: readonly string[];
  readonly isFXO: boolean;
  readonly isNDF: boolean;
  readonly isRoute: boolean;
  readonly business_address: string;
  readonly work_phone: string;
  readonly cell: string;
  readonly MPIDS: readonly MPID[];
  readonly SESSIONIDS: readonly string[];
}

export class FirmPayload {
  public static fromFirm(firm: Firm): FirmPayload {
    const { firmCategory } = firm;

    return {
      ...(firm.firmID === -1 ? {} : { FirmID: firm.firmID }),
      FirmName: toFirmName(firm.MPIDS, firm.firmName),
      FirmLabel: firm.firmLabel,
      FirmType: firm.firmType,
      STPMsgType: firm.STPMsgType,
      STPRoute: firm.STPRoute,
      STPDestination: firm.STPDestination,
      BrokerageTop: brokerageTop(firm.brokerage),
      BrokerageFloor: brokerageFloor(firm.brokerage),
      Entities: firm.entities?.map(EntityPayload.fromEntity),
      IPAddresses: firm.whitelist?.map((item: WhitelistItem): string => item.ipAddress),
      isFXO: !!firmCategory.find((item: DropdownOption): boolean => item.id === 'fxo'),
      isNDF: !!firmCategory.find((item: DropdownOption): boolean => item.id === 'ndf'),
      isRoute: !!firmCategory.find((item: DropdownOption): boolean => item.id === 'route'),
      business_address: firm.businessAddress,
      cell: firm.workPhone,
      work_phone: firm.cellPhone,
      MPIDS: firm.MPIDS,
      SESSIONIDS: firm.sessionIds.map((item: DropdownOption): string => String(item.id)),
    };
  }
}

export interface BackendFirm {
  readonly FirmID: number;
  readonly FirmName: string;
  readonly FirmLabel: string;
  readonly FirmType: FirmType;
  readonly STPDestination?: string;
  readonly STPMsgType?: STPMsgType;
  readonly STPRoute?: STPRoute;
  readonly BrokerageTop: [BrokerageLimit] | null;
  readonly BrokerageFloor: [BrokerageLimit] | null;
  readonly Entities?: readonly Entity[];
  readonly IPAddresses: readonly string[];
  readonly isFXO: boolean;
  readonly isNDF: boolean;
  readonly isRoute: boolean;
  readonly business_address: string;
  readonly cell: string;
  readonly work_phone: string;
  readonly MPIDS?: readonly MPID[];
  readonly SESSIONIDS: readonly string[];
}

export class BackendFirm {
  public static toDropdownOption(firm: BackendFirm): DropdownOption {
    return {
      id: firm.FirmID,
      name: firm.FirmLabel,
    };
  }
}

const emptyFirm: Firm = {
  id: -1,
  firmLabel: '',
  firmID: -1,
  firmName: '',
  MPIDS: [],
  firmType: '' as FirmType,
  entities: [],
  whitelist: [],
  brokerage: Brokerage.empty(),
  firmCategory: [],
  businessAddress: '',
  cellPhone: '',
  workPhone: '',
  sessionIds: [],
};

const toFirmName = (mpids: readonly MPID[] | undefined | null, fallback: string): string => {
  if (!mpids || mpids.length === 0) {
    if (fallback === '') {
      return randomString(4).toUpperCase();
    }

    return fallback;
  }

  return mpids
    .reduce((unique: readonly MPID[], next: MPID): readonly MPID[] => {
      if (unique.findIndex((item: MPID): boolean => next.mpid === item.mpid) === -1) {
        return [...unique, next];
      } else {
        return unique;
      }
    }, [])
    .map((mpid: MPID): string => mpid.mpid)
    .join(', ');
};

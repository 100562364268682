import applicationRoutes from 'applicationMenu';
import { Session } from 'auth/context';
import { User } from 'auth/user';
import { ApplicationContainer } from 'components/ApplicationContainer';
import { SideBar } from 'components/SideBar';
import { LOGIN_FLOW } from 'constants/routes';
import { useSession } from 'hooks/useSession';
import { ApplicationRouteDef } from 'interfaces/applicationRouteDef';
import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Routes } from 'react-router';
import { Navigate, Route } from 'react-router-dom';
import { getCurrentUser } from 'redux/actions/auth/getCurrentUser';
import { ApplicationState } from 'redux/applicationState';

interface DispatchProps {
  getCurrentUser(): any;
}

type Props = DispatchProps & ApplicationState;

const Prism: React.FC<Props> = (props: Props): React.ReactElement => {
  const { getCurrentUser } = props;
  const session: Session = useSession();

  const {
    currentUser: { user },
  } = props;

  React.useEffect((): VoidFunction => {
    return getCurrentUser();
  }, [getCurrentUser]);

  const menu = React.useMemo((): readonly ApplicationRouteDef[] => {
    return filteredRoutes
      .map((item: ApplicationRouteDef): ApplicationRouteDef | null => {
        if (item.available(user)) {
          return { ...item, enabled: true };
        }

        return null;
      })
      .filter((item: ApplicationRouteDef | null): boolean => item != null);
  }, [user]);

  const routes = React.useMemo((): readonly ApplicationRouteDef[] => {
    return flatten(menu);
  }, [menu]);

  if (routes.some((route: ApplicationRouteDef): boolean => route.component === undefined)) {
    throw routesBrokenError;
  }

  const currentUser = React.useMemo((): User => user ?? ({} as User), [user]);
  if (!session.isValid()) {
    return <Navigate to={LOGIN_FLOW} replace />;
  }

  return (
    <>
      <SideBar user={currentUser} menu={menu} />
      <ApplicationContainer>
        <Routes>
          {routes.map((route: ApplicationRouteDef): React.ReactElement => {
            const Component = route.component;

            return (
              <Route
                key={String(route.key)}
                path={route.path}
                element={<Component user={currentUser} />}
              />
            );
          })}
        </Routes>
      </ApplicationContainer>
    </>
  );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, any> = {
  getCurrentUser,
};

const mapStateToProps: MapStateToProps<ApplicationState, any, ApplicationState> = (
  applicationState: ApplicationState
): ApplicationState => applicationState;

export default connect(mapStateToProps, mapDispatchToProps)(Prism);

const flatten = (array: readonly ApplicationRouteDef[]): readonly ApplicationRouteDef[] => {
  const result: ApplicationRouteDef[] = [];
  for (const item of array) {
    if (item.children) {
      result.push(...flatten(item.children));
    } else {
      result.push(item);
    }
  }

  return result;
};

const routesBrokenError = new Error('there are invalid routes in your routes definition');

const filteredRoutes = applicationRoutes.filter(
  (route: ApplicationRouteDef): boolean => route.enabled
);

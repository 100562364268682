import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
//import applicationRoutes from 'applicationMenu';
//import { Session } from 'auth/context';
import { User } from 'auth/user';
import { CenteredModal } from 'components/CenteredModal';
import { Scaffold } from 'components/Scaffold';
import { StatusBar } from 'components/StatusBar';
import { Table, TableMode } from 'components/Table';
import config from 'config';
import { ProcessingState } from 'enums/processingState';
import { useCRUD } from 'hooks/useCRUD';
//import { useSession } from 'hooks/useSession';
import { useStatusFilter } from 'hooks/useStatusFilter';
import { useTable } from 'hooks/useTable';
//import { ApplicationRouteDef } from 'interfaces/applicationRouteDef';
import { Flow } from 'interfaces/flow';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncDispatch } from 'redux/asyncAction';
import {
  flowsSelector,
  flowsTotalsSelector,
  processingStateSelector,
} from 'redux/reducers/flowsReducer';
import { statusBarSelector } from 'redux/reducers/statusBarReducer';
import { fetchFlowsAction } from 'redux/services/flowsServices';
import columns from 'routes/FlowsScreen/columns';
import { CreateFlowForm } from 'routes/FlowsScreen/createFlowForm';

const { adminGroup } = config;
const isAdmin = ({ groups }: User): boolean => groups.includes(adminGroup);
const FlowsScreen: React.FC<any> = (props: any): React.ReactElement => {
  const { user } = props;
  const adminIs = isAdmin(user);
  const dispatch = useDispatch<AsyncDispatch>();
  const flows = useSelector(flowsSelector);
  const statusBar = useSelector(statusBarSelector);
  const totals = useSelector(flowsTotalsSelector);
  const processingState = useSelector(processingStateSelector);
  const [crud, mode] = useCRUD(flows, Flow.empty());
  //const filteredRoute = applicationRoutes.find(
  //  (route: ApplicationRouteDef): any => route.label === 'Flows'
  //);
  //const session: Session = useSession();
  const tableProps = useTable('flows', columns);
  const [filteredFlows, filter, setFilter] = useStatusFilter<Flow>(flows);

  React.useEffect((): void => {
    dispatch(fetchFlowsAction());
  }, [dispatch]);
  return (
    <Scaffold title="Flows Monitor">
      <StatusBar
        totals={totals}
        filter={filter}
        lastUpdate={statusBar.lastUpdate}
        connected={statusBar.connected}
        onFiltered={setFilter}
      />
      {adminIs && (
        <Box marginBottom={1}>
          <Button onClick={crud.add}>
            <Add /> Add New
          </Button>
        </Box>
      )}
      <Table
        rowKey="id"
        rows={filteredFlows}
        loading={ProcessingState.isProcessing(processingState)}
        {...tableProps}
      />
      <CenteredModal open={mode === TableMode.create} onClose={crud.reset}>
        <CreateFlowForm />
      </CenteredModal>
    </Scaffold>
  );
};
export default FlowsScreen;

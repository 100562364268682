import { User } from 'auth/user';
import config from 'config';
import {
  EXECUTIONS,
  FLOWS,
  MESSAGES,
  REPORTS_WHO_IS_LOGGED_IN,
  SESSIONS,
  SOLACE_LOGS,
  TOOLS_ADD_REMOVE_FIRMS,
  TOOLS_ADD_REMOVE_USERS,
} from 'constants/routes';
import { ApplicationRouteDef } from 'interfaces/applicationRouteDef';
import FirmsManagement from 'routes/EntityManagement/FirmsManagement';
import UsersManagement from 'routes/EntityManagement/UsersManagement';
import { Executions } from 'routes/Executions';
import FlowsScreen from 'routes/FlowsScreen';
import MessagesScreen from 'routes/Messages';
import { Profitability } from 'routes/ProfitabilityReport';
import WhoIsLoggedIn from 'routes/ReportsRoute/WhoIsLoggedIn';
import { Risk } from 'routes/Risk';
import SessionsScreen from 'routes/SessionsScreen';
import { SolaceLogs } from 'routes/SolaceLogs';

const { messagesAndFlowsGroups, fxoGroups, adminGroup } = config;

const isFXOBroker = ({ groups }: User): boolean => {
  return groups.includes(fxoGroups.broker);
};

const isBrokerAdmin = ({ groups }: User): boolean => groups.includes(fxoGroups.brokerAdmin);

export const isAdmin = ({ groups }: User): boolean => groups.includes(adminGroup);

const isEntitledToMessagesAndFlows = ({ groups }: User): boolean =>
  !!groups.find((group: string): boolean => messagesAndFlowsGroups.includes(group));

const isPrismUser = ({ groups }: User): boolean =>
  !!groups.find((group: string): boolean => messagesAndFlowsGroups.includes(group));

const applicationRoutes: readonly ApplicationRouteDef[] = [
  {
    label: 'Flows',
    path: FLOWS,
    available: (user: User): boolean => isAdmin(user) || isEntitledToMessagesAndFlows(user),
    enabled: true,
    key: 'flows',
    component: FlowsScreen,
    exact: false,
  },
  {
    label: 'Sessions',
    path: SESSIONS,
    available: (user: User): boolean => isAdmin(user),
    enabled: true,
    key: 'sessions',
    component: SessionsScreen,
    exact: false,
  },
  {
    label: 'Messages',
    path: MESSAGES,
    available: (user: User): boolean => isAdmin(user) || isEntitledToMessagesAndFlows(user),
    enabled: true,
    key: 'messages',
    component: MessagesScreen,
    exact: false,
  },
  {
    label: 'Executions',
    path: EXECUTIONS,
    available: (user: User): boolean => isAdmin(user) || isEntitledToMessagesAndFlows(user),
    enabled: true,
    key: 'executions',
    component: Executions,
    exact: false,
  },
  {
    label: 'Info',
    path: '',
    key: 'solace',
    enabled: true,
    exact: false,
    available: (user: User): boolean => isAdmin(user) || isBrokerAdmin(user) || isFXOBroker(user),
    children: [
      {
        label: 'Logs',
        path: SOLACE_LOGS,
        available: (user: User): boolean => isAdmin(user) || isEntitledToMessagesAndFlows(user),
        enabled: true,
        key: 'solace-logs',
        component: SolaceLogs,
        exact: false,
      },
    ],
  },
  {
    label: 'Entity Management',
    path: '',
    enabled: true,
    available: (user: User): boolean => isAdmin(user) || isBrokerAdmin(user) || isFXOBroker(user),
    key: 'tools',
    children: [
      {
        label: 'Manage users',
        path: TOOLS_ADD_REMOVE_USERS,
        available: (_: User): boolean => true,
        enabled: true,
        key: 'tools/users/manage',
        component: UsersManagement,
        exact: true,
      },
      {
        label: 'Manage firms',
        path: TOOLS_ADD_REMOVE_FIRMS,
        available: (_: User): boolean => true,
        enabled: true,
        key: 'tools/firms/manage',
        component: FirmsManagement,
        exact: true,
      },
    ],
    exact: false,
  },
  {
    label: 'Reports',
    path: '',
    enabled: true,
    key: 'reports',
    available: (user: User): boolean =>
      isAdmin(user) || isBrokerAdmin(user) || isFXOBroker(user) || isPrismUser(user),
    children: [
      // {
      //   label: 'Who is logged in?',
      //   path: REPORTS_WHO_IS_LOGGED_IN,
      //   enabled: true,
      //   key: 'reports/who_is_logged_in',
      //   available: (user: User): boolean => isAdmin(user),
      //   component: WhoIsLoggedIn,
      //   exact: true,
      // },
    ],
    exact: false,
  },
  {
    label: 'Who is logged in?',
    path: REPORTS_WHO_IS_LOGGED_IN,
    enabled: true,
    key: 'reports/who_is_logged_in',
    available: (user: User): boolean => isAdmin(user) || isBrokerAdmin(user) || isFXOBroker(user),
    component: WhoIsLoggedIn,
    exact: true,
  },
  {
    label: 'Risk',
    path: 'risk',
    enabled: true,
    key: 'risk',
    available: (user: User): boolean => isAdmin(user) || isPrismUser(user),
    exact: true,
    component: Risk,
  },
  {
    label: 'Profitability',
    path: 'profitability',
    enabled: true,
    key: 'profitability',
    available: (user: User): boolean => isAdmin(user),
    exact: true,
    component: Profitability,
  },
];

export default applicationRoutes;

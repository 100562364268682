import { FormLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { Dispatch } from '@reduxjs/toolkit';
import { toClassName } from 'helpers/toClassName';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { fetchRiskSessionsAction } from 'redux/actions/riskActions';
import { AsyncAction } from 'redux/asyncAction';
import { RiskEngine, riskSelector, setClient, setSessionID } from 'redux/reducers/riskReducer';
import classes from 'routes/Risk/risk.module.scss';

interface Props {
  readonly disabled: boolean;
}

export const RiskHeader: React.FC<Props> = (props: Props): React.ReactElement => {
  const dispatch = useDispatch<Dispatch<AsyncAction | AnyAction>>();
  const risk = useSelector(riskSelector);

  const { clientID, engines, sessionID, sessions } = risk;

  const handleClientChange = React.useCallback(
    (event: SelectChangeEvent): void => {
      const { value } = event.target;
      dispatch(setClient(value));
    },
    [dispatch]
  );

  const handleSessionChange = React.useCallback(
    (event: SelectChangeEvent): void => {
      const { value } = event.target;
      dispatch(setSessionID(value));
    },
    [dispatch]
  );

  React.useEffect((): void => {
    if (clientID === '') {
      return;
    }

    dispatch(fetchRiskSessionsAction(clientID));
  }, [clientID, dispatch]);

  return (
    <form
      className={toClassName(classes.form, {
        [classes.disabled]: props.disabled,
      })}
    >
      <fieldset className={classes.fieldset}>
        <FormLabel>Firm Name</FormLabel>
        <Select value={clientID} fullWidth={true} onChange={handleClientChange}>
          {engines.map(
            (client: RiskEngine): React.ReactElement => (
              <MenuItem key={client.sessionid} value={client.sessionid}>
                {client.clientid}
              </MenuItem>
            )
          )}
        </Select>
      </fieldset>

      {/* <fieldset className={classes.fieldset}>
        <FormLabel>Session ID</FormLabel>
        <Select
          value={sessionID}
          fullWidth={true}
          disabled={sessions.length === 0}
          onChange={handleSessionChange}
        >
          {sessions.map(
            (session: RiskSession): React.ReactElement => (
              <MenuItem key={session.sessionid} value={session.sessionid}>
                {session.sessionid}
              </MenuItem>
            )
          )}
        </Select>
      </fieldset> */}

      <fieldset className={classes.fieldset}>
        <FormLabel>Sender COMP ID</FormLabel>
        <OutlinedInput value={clientID ?? ''} fullWidth readOnly />
      </fieldset>
    </form>
  );
};
